import React, { useState, useEffect, useMemo, useCallback } from "react";
import "../styles/PrizeList.scss";
import axios from "axios";
import * as XLSX from "xlsx";
// 날짜 포맷팅 함수 (UTC -> 로컬 시간 변환)
const formatServerDate = (serverDate) => {
  const date = new Date(serverDate);
  if (isNaN(date)) return "Invalid Date";

  // 로컬 시간으로 변환
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  const second = date.getSeconds().toString().padStart(2, "0");

  return `${year}. ${month}. ${day}. ${hour}:${minute}:${second}`;
};

// 상금 포맷팅 함수
const formatPrize = (prize) => prize?.toLocaleString() || "0";

// 메인 컴포넌트
function PrizeList() {
  const serverApi = process.env.REACT_APP_AWS_API;
  const [prizeListData, setPrizeListData] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "none",
  });

  const getPrizeList = async () => {
    if (!filterStartDate || !filterEndDate) {
      alert("날짜를 입력해주세요.");
      return;
    }

    try {
      const res = await axios.get(
        `${serverApi}/api/cms/tournament/period/prize/money?start_date=${filterStartDate}&end_date=${filterEndDate}`
      );
      const formattedData = res.data.map((item) => ({
        "일시(토너먼트 종료 로그기준)": formatServerDate(
          item.tournament_closed
        ),
        체인명: item.select_network,
        게임명: item.game_name,
        상금구분: item.adminssion_type,
        상금액: formatPrize(item.prize_money),
        입장토큰구분: item.entry_type,
        입장료: formatPrize(item.entry_fee_money),
        상태값: item.status,
      }));
      setPrizeListData(formattedData);
    } catch (e) {
      console.error("Error fetching prize list data:", e);
    }
  };
  const downloadExcel = () => {
    if (prizeListData.length === 0) {
      alert("다운로드할 데이터가 없습니다.");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(prizeListData); // JSON 데이터를 워크시트로 변환
    const workbook = XLSX.utils.book_new(); // 새 워크북 생성
    XLSX.utils.book_append_sheet(workbook, worksheet, "PrizeList"); // 워크북에 워크시트 추가

    // 파일 저장
    XLSX.writeFile(workbook, "PrizeList.xlsx"); // 파일 이름 지정
  };
  const handleFilterStartDateChange = (e) => {
    setFilterStartDate(e.target.value);
  };
  const handleFilterEndDateChange = (e) => {
    setFilterEndDate(e.target.value);
  };

  const sortData = useCallback(
    (key) => {
      let direction = "asc";
      if (sortConfig.key === key) {
        if (sortConfig.direction === "asc") direction = "desc";
        else if (sortConfig.direction === "desc") direction = "none";
      }

      setSortConfig({ key, direction });

      if (direction === "none") {
        return; // 정렬 취소
      }

      const sortedData = [...prizeListData].sort((a, b) => {
        let aValue = a[key];
        let bValue = b[key];

        // 숫자 정렬
        if (key === "상금액" || key === "입장료(금액)") {
          aValue = Number(String(aValue).replace(/,/g, "")) || 0;
          bValue = Number(String(bValue).replace(/,/g, "")) || 0;
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          return direction === "asc" ? aValue - bValue : bValue - aValue;
        }

        return direction === "asc"
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      });

      setPrizeListData(sortedData);
    },
    [prizeListData, sortConfig]
  );

  return (
    <div className="main">
      <div className="main--inner">
        <div className="header--box">
          <div className="main--title">상금 리스트</div>
          <button
            onClick={downloadExcel}
            className={`xlsx-down ${
              prizeListData.length === 0 ? "disabled" : ""
            }`}
          >
            엑셀 다운로드
          </button>
        </div>
        {/* 기간 범위 조회 */}
        <div className="date--range">
          <input
            type="date"
            value={filterStartDate}
            onChange={handleFilterStartDateChange}
          />
          ~
          <input
            type="date"
            value={filterEndDate}
            onChange={handleFilterEndDateChange}
          />
          <button className="search--btn" onClick={getPrizeList}>
            조회
          </button>
        </div>
        {prizeListData.length === 0 && <div>조회된 데이터가 없습니다.</div>}
        <table
          className="prize--list--table"
          border="1"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              {prizeListData.length > 0 &&
                Object.keys(prizeListData[0]).map((key) => (
                  <th
                    key={key}
                    style={{
                      padding: "8px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => sortData(key)} // 헤더 클릭 시 정렬
                  >
                    {key}{" "}
                    {sortConfig.key === key
                      ? sortConfig.direction === "asc"
                        ? "↑"
                        : sortConfig.direction === "desc"
                        ? "↓"
                        : ""
                      : ""}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {prizeListData.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((cell, i) => (
                  <td key={i} style={{ padding: "8px", textAlign: "center" }}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PrizeList;
